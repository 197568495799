// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: clip;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

//TOP BAR
.top-bar {
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 13px 32px;
    color: @footer-gray;
    font-weight: 300;
    display: flex; 
    justify-content: center;
    z-index: 2;

    > div {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        letter-spacing: 0.1em;
        padding: 0 40px;
    }

    &:before {
        .full-bg();
        background-color: #161616;
        z-index: -1;
    }
}

[data-type="cart"] .top-bar {
    display: none !important;
}

.top-bar-close {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    aspect-ratio: 1;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        .small-icon();
        content: "\e916";
        font-size: 24px;
    }
}

.header-sentinel {
    height: 0;
    clear: both;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}


// SHOP-HEADER
@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: transparent;
        width: 100%;
        transition: transform .3s ease-in-out;
        z-index: 1000;

        &.stuck {
            transform: translateY(-76px);

            .logo {
                height: 64px;
                bottom: 20px;
            }

            .shop-header-container {
                /*padding-bottom: 2px;*/

                &:before {
                    top: -6px;
                    .shadow();
                }
            }

            #suggestions {
                top: ~"calc(100% + 20px)";
            }
        }
    }

    .logo {
        transition: all 0.3s ease-in-out;
    }
    .shop-header-container {
        transition: padding 0.3s ease-in-out;
    }
}

.shop-header-container {
    .grid();
    grid-template-columns: minmax(0, 1fr) auto;
    padding-block: 35px 8px;
    padding-inline: 32px;
    position: relative;
    gap: 40px 24px;
    background: transparent;

    &:before {
        .full-bg();
        background-color: @sec-color;
        transition:
            top .3s ease-in-out,
            box-shadow .3s ease-in-out;
    }

    .header-contact {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        align-items: center;
        justify-items: start;
    }

    .header-social-links {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        align-items: center;
        justify-items: end;
    }

    #main-bar {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        align-items: center;
        justify-items: start;
    }

    .header-tools-wrapper {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        align-items: end;
        justify-items: end;
    }
}

.header-contact {
    display: flex;
    align-items: center;
    gap: 24px;

    a {
        padding-left: 32px;
        position: relative;
        color: @footer-gray;
        font-weight: 300;
        font-size: 14px;

        &:before {
            .small-icon();
            color: white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }

        &.email:before {
            content: "\e915";
        }

        &.email:after { // yellow dot
            content: '';
            position: absolute;
            left: 12px;
            top: 0;
            background: @sec-color;
            border: 1px solid #F9D200;
            border-radius: 50%;
            width: 7px;
            height: 7px;
        }

        &.phone:before {
            content: "\e914";
        }

        @media(min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.header-social-links {
    display: flex;
    gap: 6px;
    justify-content: flex-end;

    a {
        width: 24px;
        height: 24px;
        color: white;

        &:before {
            .small-icon();
            font-size: 24px;
        }

        &.facebook-link:before {
            content: "\e913";
        }

        &.instagram-link:before {
            content: "\e912";
        }

        @media (min-width: 1280px) {
            &:hover {
                color: @main-color;
            }
        }
    }
}

// LOGO
.logo {
    position: absolute;
    left: 50%;
    bottom: 12px;
    transform: translateX(-50%);
    height: 144px;
    aspect-ratio: ~"209/144";
    z-index: 104;

    img {
        .img-contain();
    }
}

// HEADER TOOLS WRAPPER
.header-tools-wrapper {
    display: flex;
    gap: 32px;
    position: relative;
    padding-bottom: 18px;
    z-index: 105;
}

.search-engine-trigger,
.log-button,
#cart-box,
.tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 32px;

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        line-height: 1;
        color: white;
        transition: color 0.1s ease-in-out;
    }

    em {
        color: @footer-gray;
        font-style: normal;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: normal;
        display: block;
        line-height: 1.2;
        letter-spacing: 0.1em;
    }

    @media (min-width: 1280px) {
        &:hover:before {
            color: @main-color;
        }
    }
}

.search-engine-trigger {

    &:before {
        content: "\e911";
    }
}

.log-button {

    &:before {
        content: "\e910";
    }
}

#cart-box {

    span,
    b {
        display: none;
    }

    &:before {
        content: "\e90f";
    }

    i {
        font-size: 0;
        position: absolute;
        top: 3px;
        right: 4px;
        width: 6px;
        height: 6px;
        background-color: #F9D200;
        border-radius: 50%;
    }
}

.hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    span {
        display: block;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background-color: white;

        &:nth-child(1) {
        }

        &:nth-child(2) {
        }

        &:nth-child(3) {
        }
    }
}

@media (min-width: 1280px) {
    .tree-trigger,
    .tree-canvas {
        display: none;
    }

    #cart-box {
        position: relative;
        padding-left: 36px;
        padding-top: 0;
        transform: translateY(-7px);
        align-self: center;

        &:before {
            font-family: icomoon;
            left: 0;
            height: 24px;
            transform: none;
        }

        em {
            display: none;
        }

        span,
        b {
            display: block;
        }

        span {
            font-size: 10px;
            line-height: 1.2;
            letter-spacing: 0.1em;
            color: @footer-gray;
            text-transform: uppercase;
        }

        b {
            font-size: 14px;
            color: white;
        }

        i {
            top: initial;
            bottom: 6px;
            left: 9px;
        }
    }
}

// SEARCH ENGINE
@media (min-width: 1280px) {

    #search-engine {
        background: transparent;
        position: absolute;
        right: calc(100% - 48px);
        top: -3px;
        width: 320px;
        z-index: 100;
        transition: all 0.2s ease-in-out 0.1s;
        opacity: 0;
        visibility: hidden;
        align-items: center;
        margin-right: 60px;
        transform: translateX(10px);

        &:hover {
            transition-delay: 0s;
            transition: all 0.1s ease-in-out;
        }

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 40px 0 22px;
            background-color: white;
            color: @header-color;
            font-size: 15px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @font-color;
            }

            &::-moz-placeholder {
                color: @font-color;
            }

            &:-ms-input-placeholder {
                color: @font-color;
            }

            &:-moz-placeholder {
                color: @font-color;
            }
        }

        #searchbutton {
            position: absolute;
            top: 0;
            right: -60px;
            width: 42px;
            height: 58px;
            cursor: pointer;
            color: @header-color;
            background: white;
            display: flex;
            background: transparent;
            justify-content: center;
            align-items: center;
        }

        .search-engine-close {
            font-size: 0;
            width: 24px;
            aspect-ratio: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            cursor: pointer;

            &:after {
                content: "\e916";
                .small-icon();
                font-size: 24px;
            }
        }
    }

    body.show-search-engine {
        #search-engine {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// MAIN BAR
@media (min-width: 1280px) {

    #main-bar {
        position: relative;
        display: flex;
        justify-content: flex-start;
        gap: 32px;
        z-index: 103;
        max-width: 60%; //doesn't go under search popup while having higher z-index
    }

    .mainmenu,
    #tree-top {

        a {
            font-size: 14px;
            letter-spacing: 0.1em;
            font-weight: 300;
            color: white;
            white-space: nowrap;
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: space-between;
            transition: color 0.1s ease-in-out;

            &:hover {
                color: @main-color;
            }
        }

        .tree-top-hover, // controls main bar height
        > li > a {
            padding: 18px 0;
        }
    }



    .mainmenu {
        display: contents;

        li.selected a {
            color: @main-color;
        }
    }

    .mainmenu > li:nth-of-type(1) {
        order: -1;
    }
    .tree-top-hover {
        order: 0;
    }

    // TREE
    #tree-top,
    #tree-top li { // both nested lists
        position: relative;

        small {
            display: none;
        }

        ul {
            position: absolute;
            background-color: #161616;
            min-width: 264px;
            padding: 16px 0;
            border-radius: 0;
            transition: all .2s ease-in-out;
            transition-delay: 0.2s;
            opacity: 0;
            visibility: hidden;

            a {
                padding: 8px 24px;

                &:after {
                    transform: rotate(-90deg);
                }
            }
        }

        .tree-top-hover:after,
        .parent > a:after { // arrows
            .small-icon();
            content: "\e91a";
            font-size: 20px;
            transition: transform 0.2s;
        }

        &:hover {

            > ul {
                opacity: 1;
                visibility: visible;
                transform: none !important;
            }

            > a {
                color: @main-color !important;
            }
        }
    }

    #tree-top { // first nested list

        > ul {
            top: 100%;
            left: -24px;
            transform: translateY(-10px); // for animation

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 8px;
                width: 100%;
                left: 0;
                top: -4px;
            }
        }

        &:hover .tree-top-hover:after {
            transform: rotate(180deg);
            transition-delay: 0.2s;
        }
    }

    #tree-top li { // side nested lists

        ul {
            left: calc(100% + 1px);
            top: -16px;
            transform: translateX(-10px); // for animation

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 100%;
                width: 8px;
                top: 0;
                left: -4px;
            }
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;

    &:empty {
        display: none;
    }
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto auto 1fr;
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: 300;
    display: block;
    color: @header-color;
    transition: opacity .2s ease-in-out;
    z-index: 1; // IMPORTANT
    margin-top: 24px;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    } 

    &:first-letter {
        text-transform: uppercase;
    }
}

.short-desc {
    margin-top: 4px;
    font-weight: 300;
    color: rgba(0,0,0, 0.6);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-bottom-wrapper {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    z-index: 1;
    align-self: end;
}

.product-price {
    height: 34px;
    display: flex;
    align-items: center;
    gap: 10px;

    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: @header-color;
    }

    del {
        color: rgba(0,0,0, 0.6);
    }
}

.product-add {
    letter-spacing: 0.1em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: @header-color;
    border: 1px solid @header-color;
    width: 34px;
    height: 34px;
    font-size: 12px;
    padding: 8px 36px;

    @media(min-width: 1280px) {
        &:hover {
            border-color: @main-color;
        }
    }

    &.disabled {
        cursor: default;
        border-color: darken(@border-color, 28%);
        color: darken(@border-color, 28%) !important;
    }
}

.product-badges {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;

    span {
        font-size: 14px;
        font-weight: 700;
        padding: 4px 8px;
        text-align: center;
        color: white;
        background-color: @header-color;

        &.promo-badge {
            color: @header-color;
            background-color: @main-color;
        }
    }
}

@media (min-width: 1280px) {
    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
            transition: opacity .2s ease-in-out;
            transition-delay: 0.15s;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
            animation-delay: 0.15s;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding-block: 24px;
    font-size: 14px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 6px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @header-color;
        display: block;
        font-weight: 300;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

main:has(#breadcrumb) #content {
    margin-top: calc(-1 * var(--rowGap));
}

[data-type="cart"] #content {
    margin-top: 0 !important;
}

[data-type="listing"] #breadcrumb {
    padding-top: 0;
    padding-bottom: 24px;
}

[data-type="detail"] #breadcrumb {
    padding-top: 40px;
    padding-bottom: 13px;
}

// FREE SHIPMENT
.free-shipment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: @light-bg;

    .free-shipment-info {
        padding: 16px;
    }

    .progress-bar-container {
        background: transparent;
        width: 100%;
        height: 4px;

        .progress-bar {
            width: 0;
            max-width: 100%;
            height: 100%;
            background: @main-color;
            transition: width 0.3s ease-in-out;
        }
    }
}